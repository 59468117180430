import {ref} from 'vue';
import { defineStore } from 'pinia';

import {db} from '@/firebase/config.js'
import { collection, getDocs,  getDoc, doc, query} from 'firebase/firestore'

const SubjectId = Object.freeze({
    IDENTIFICATION_GENERAL: 'identification-general',
    IDENTIFICATION_AIRCRAFT: 'identification-aircraft',
    HANDS_ON_PERFORMANCE: 'hands-on-performance',
    EXPLANATION_OF_PROCESS: 'explanation-of-process',
});


export const usePlusSurveyStore = defineStore('plusSurveyStore', () => {
    
    const isInit = ref(false);
    const plusExamSurvey = ref(null);
    const sites = ref([
        {id:'patts', name: 'PATTS'},
        {id:'omni', name: 'OMNI'},
        {id:'philsca', name: 'PHILSCA'},
        {id:'feati', name: 'FEATI'},
        {id:'others', name: 'Others'}
    ]);
    const subjects = ref({
        [SubjectId.IDENTIFICATION_GENERAL]:{id: SubjectId.IDENTIFICATION_GENERAL, name: 'Identification General', topics: []},
        [SubjectId.IDENTIFICATION_AIRCRAFT]:{id: SubjectId.IDENTIFICATION_AIRCRAFT, name: 'Identification Aircraft', topics: []},
        [SubjectId.HANDS_ON_PERFORMANCE]:{id: SubjectId.HANDS_ON_PERFORMANCE, name: 'Hands on Performance', topics: []},
        [SubjectId.EXPLANATION_OF_PROCESS]:{id: SubjectId.EXPLANATION_OF_PROCESS, name: 'Explanation of Process', topics: []},
    })

    const fetchSubtopics = async () => {

        subjects.value[SubjectId.IDENTIFICATION_GENERAL].topics = [];
        subjects.value[SubjectId.IDENTIFICATION_AIRCRAFT].topics = [];
        subjects.value[SubjectId.HANDS_ON_PERFORMANCE].topics = [];
        subjects.value[SubjectId.EXPLANATION_OF_PROCESS].topics = [];
        
        const querySnapshot = await getDocs(collection(db,'reviewers','plus','subtopics'))
    
        querySnapshot.forEach((doc)=>{

            let _id = doc.id;
            let _data = doc.data();

            // For Identification General Subject
            if (_data.subjects.includes(SubjectId.IDENTIFICATION_GENERAL)) {
                subjects.value[SubjectId.IDENTIFICATION_GENERAL].topics.push({
                    id: _id,
                    name: _data.name
                });
            }

            // For Identification Aircraft Subject
            if (_data.subjects.includes(SubjectId.IDENTIFICATION_AIRCRAFT)) {
                subjects.value[SubjectId.IDENTIFICATION_AIRCRAFT].topics.push({
                    id: _id,
                    name: _data.name
                });
            }

            // For Hands on Performance Subject
            if (_data.subjects.includes(SubjectId.HANDS_ON_PERFORMANCE)) {
                subjects.value[SubjectId.HANDS_ON_PERFORMANCE].topics.push({
                    id: _id,
                    name: _data.name
                });
            }

            // For Explanation of Process Subject
            if (_data.subjects.includes(SubjectId.EXPLANATION_OF_PROCESS)) {
                subjects.value[SubjectId.EXPLANATION_OF_PROCESS].topics.push({
                    id: _id,
                    name: _data.name
                });
            }

        })

    }

    // const countResponsesPerSubject = () => {
    // }

    const fetchPlusExamSurvey = async (isForce = false) => {

        if (isInit.value == false || isForce) {
            
            plusExamSurvey.value = [];

            const querySnapshot = await getDocs(collection(db,'plus_exam_survey'))
    
            querySnapshot.forEach((doc)=>{

                plusExamSurvey.value.push({
                    id: doc.id,
                    data: doc.data()
                })

            })

            await fetchSubtopics();

            isInit.value = true;
        }


    }

    const fetchUser = async (uid) => {
        return new Promise(async (resolve, reject) => {
      
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);
      
          if (docSnap.exists()) {
            let data = docSnap.data()
            data.id = docSnap.id;
            resolve(data)
          } else {
            // doc.data() will be undefined in this case
            console.error("No such document!")
          }
        })
      }

    const fetchAllSchools = async () => {
        return new Promise(async (resolve, reject) => {
          getDocs( query(collection(db, "schools")))
            .then((querySnapshot) => { 
              if(querySnapshot.size > 0){
                var _schools = [];
                querySnapshot.forEach((doc) => {
                  let data = doc.data()
                  data.id = doc.id
                  _schools.push(data)
                });
                resolve(_schools)
              }else{
                console.log("Query returned no results!")
              }
            })
            .catch((error) => {
              console.error("Error retreiving schools list from DB");
              throw `Error getting user document: ${error}`;
            });
        })
      }

    return {
        isInit, subjects, plusExamSurvey, sites,
        fetchPlusExamSurvey, fetchUser, fetchAllSchools
    }
})