<script setup>
import {ref, computed } from 'vue';
import { ArrowLeftIcon, StarIcon } from '@heroicons/vue/24/outline';
import { StarIcon as StarIconSolid } from '@heroicons/vue/24/solid';

import moment from "moment";

import { useRoute } from 'vue-router';
const route = useRoute();

import { storeToRefs } from 'pinia';

import { usePlusSurveyStore } from '../store/plusSurveyStore';
const plusSurveyStore = usePlusSurveyStore();
const { subjects, sites, plusExamSurvey} = storeToRefs(plusSurveyStore);
const { fetchPlusExamSurvey,fetchUser, fetchAllSchools } = plusSurveyStore;

const currentSubject = ref(null);
const currentUser = ref(null);
const currentResponse = ref(null);
const schools = ref([])

const testSite = computed(()=>{
    let _site = sites.value.find(site => site.id == currentResponse.value.data.site);
    return _site ? _site.name : '---';
})

const init = async () => {

    currentSubject.value = subjects.value[route.params.subjectId];

    let _res = null;
    [schools.value, currentUser.value, _res] = await Promise.all([
        fetchAllSchools(),
        fetchUser(route.params.userId),
        fetchPlusExamSurvey(),
    ])

    currentResponse.value = plusExamSurvey.value.find((res) => res.id == route.params.userId);
}

init();

</script>
<template>
    <div class=" flex justify-center text-warm-gray-300">
        
        <div v-if="currentUser" class=" max-w-3xl mt-8 w-full">

            <div class=" flex justify-between items-center">
                <h1 class=" text-xl font-semibold text-warm-gray-100">{{ currentUser.firstname }} {{ currentUser.lastname }}</h1>
                <router-link :to="'/plus-survey/'+currentSubject.id" class=" text-warm-gray-500 justify-center items-center flex gap-1">
                    <ArrowLeftIcon class="w-4 h-4"/>
                    Go Back
                </router-link>
            </div>

            <div class=" p-2">

                <div class="mt-8 text-warm-gray-400 leading-loose">
                    <p class=""> <b class=" text-warm-gray-300">Email:</b> {{ currentUser.email }}</p>
                    <p class=""> <b class=" text-warm-gray-300">School:</b> {{ schools.filter( e => e.id == currentUser.school)[0].name }}</p>
                    <p class=""> <b class=" text-warm-gray-300">Course:</b> {{ currentUser.course }}</p>
                    <br>
                    <p class=""> <b class=" text-warm-gray-300">Date Signed Up:</b> {{ moment.unix(currentUser.date_created/1000).format('LLLL') }}</p>
                    <p class=""> <b class=" text-warm-gray-300">Date Purchased: </b> {{ moment.unix(currentUser.caap_plus_data.purchase_date/1000).format('LLLL') }}</p>
                    <p class=""> <b class=" text-warm-gray-300">Activation Date:</b> {{ moment.unix(currentUser.caap_plus_data.activation_date.seconds).format('LLLL') }}</p>
                    <p class=""> <b class=" text-warm-gray-300">Expiration Date:</b> {{ moment.unix(currentUser.caap_plus_data.expiration_date.seconds).format('LLLL') }}</p>
                    <br>
                    <p class=""> <b class=" text-warm-gray-300">Date of exam:</b> {{ moment.unix(currentResponse.data.date.seconds).format('LLLL') }}</p>
                    <p class=""> <b class=" text-warm-gray-300">Skill Test Site:</b> {{ testSite }}</p>
                    <br>
                    <p class=""> <b class=" text-warm-gray-300">Overall Rating:</b> <StarIconSolid v-for="r in currentResponse.data.rating" class=" inline-flex w-5 h-5 mb-1 text-yellow-400"/> <StarIcon v-for="r in (5 - currentResponse.data.rating)" class=" inline-flex w-5 h-5 mb-1 text-yellow-400"/> </p>
                    <p class=""> <b class=" text-warm-gray-300">Overall Comment/Suggestion:</b></p>
                    <p class="">{{ currentResponse.data.comments }}</p>
                    
                </div>
            </div>


        </div>


    </div>
</template>